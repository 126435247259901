.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipText {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 1);
  color: black;
  border-radius: 4px;
  font-size: 12px;
  visibility: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1000;
  margin-bottom: 8px;
  box-shadow: 0px 1px 4px -3px black;
  max-width: 300px;
  min-width: 200px;
  text-align: center;
}

.tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.visible {
  visibility: visible;
  opacity: 1;
}
